export default {
    api: {
        host: '/api',
    },
    settings: {
        enableFileUpload: true,
        disableUsers: false,
        forcedLanguage: '',
    },
};
